import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import footerStyle from '../Footer/footer.module.css'

class BA extends React.Component {
  render() {
    const BAtext = get(this, 'props.text.ca')

    return (
      <div className={footerStyle.footer}>
        <div id="cc">
          <h3>
            <b>PLEASE NOTE:</b>
          </h3>
          <div
            className="content entry-sub"
            dangerouslySetInnerHTML={{
              __html: BAtext.banumber.boardAndAccText.childMarkdownRemark.html,
            }}
          />
          {/* <div>
            <strong>
              <p>
                The information contained in this news release combines both
                major market and national sales information from MLS&reg;
                Systems from the previous month.
              </p>
              <p>
                CREA cautions that average price information can be useful in
                establishing trends over time, but does not indicate actual
                prices in centres comprised of widely divergent neighbourhoods
                or account for price differential between geographic areas.
                Statistical information contained in this report includes all
                housing types.
              </p>
            </strong>
            <p>
              MLS&reg; Systems are co-operative marketing systems used only by
              Canada&rsquo;s real estate Boards to ensure maximum exposure of
              properties listed for sale.
            </p>
            <p>
              The Canadian Real Estate Association (CREA) is one of Canada’s
              largest single-industry associations, representing more than
              160,000 REALTORS® working through 65 real estate boards and
              associations.
            </p>
          </div> */}
        </div>

        {/*         <!--  =========================================================== -->
<!--  BOARD CONTACT INFORMATION HERE ============================ -->
<!--  =========================================================== --> */}
        <div className="board_contact">
          <p>
            <strong>For more information, please contact:</strong> <br />
            <br />
            <strong>Media only:</strong> <br />
            Pierre Leduc
            <br />
            Media Relations
            <br />
            Tel.: 613-237-7111 or 613-884-1460
            <br />
            E-mail:&nbsp;
            <a
              href="mailto:pleduc@crea.ca"
              className="emailcolor"
              alt="pleduc@crea.ca"
              title="pleduc@crea.ca"
            >
              <span className="emailcolor">pleduc@crea.ca</span>
            </a>
            <br />
            <br />
            <strong>All other requests:</strong> <br />
            Jacob Coopersmith
            <br />
            MLS&reg; Statistics Coordinator
            <br />
            E-mail:&nbsp;
            <a
              href="mailto:jcoopersmith@crea.ca"
              className="emailcolor"
              alt="jcoopersmith@crea.ca"
              title="jcoopersmith@crea.ca"
            >
              <span className="emailcolor">jcoopersmith@crea.ca</span>
            </a>
          </p>
          <img
            src={require('../../images/mls_logo.png')}
            width="130"
            height="64"
            alt="MLS Logo"
            title="MLS Logo"
          />
          <hr />
        </div>
      </div>
    )
  }
}
// export default FooterSub

export default props => (
  <StaticQuery
    query={graphql`
      query {
        ca: contentfulNational(node_locale: { eq: "en-CA" }) {
          node_locale
          banumber {
            boardAndAccText {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <BA text={data} />
      </div>
    )}
  />
)
