import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class PopupDataHpi extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dd1: false,
      modal1: false,
      modal2: false,
    }
    this.dropdownToggle = this.dropdownToggle.bind(this)
  }

  dropdownToggle() {
    this.setState({
      dd1: !this.state.dd1,
    })
  }
  closeModal(modal) {
    this.setState({
      [modal]: false,
    })
  }
  showModal(modal) {
    this.setState({
      [modal]: true,
    })
  }

  render() {
    const style = {
      marginTop: '10px',
      marginBottom: '20px',
    }
    const ButtonStyle = {
      background: '#345fa8',
    }

    const AcceptBtn = {
      background: 'white',
      color: '#345fa8',
      fontWeight: 'bold',
      fontSize: '20px',
      fontDecoration: 'underline',
    }
    const scroller = {
      maxHeight: '500px',
      height: '50%',
      overflowY: 'scroll',
    }

    const post = this.props.popupLegal.LegalEn
    const DataPost = this.props.popupLegal.DataEN.hpiData.file.url

    return (
      <div style={style}>
        <Button
          style={ButtonStyle}
          onClick={this.showModal.bind(this, 'modal1')}
        >
          Download HPI Data
        </Button>

        <Modal
          isOpen={this.state.modal1}
          toggle={this.closeModal.bind(this, 'modal1')}
          className={this.props.className}
          alt="Chart of interest B"
        >
          <ModalHeader toggle={this.closeModal.bind(this, 'modal1')}>
            <h1>{post.title}</h1>
          </ModalHeader>
          <ModalBody style={scroller}>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: post.body.childContentfulRichText.html,
              }}
            />
            <ModalFooter
              toggle={this.closeModal.bind(this, 'modal1')}
              style={{ justifyContent: 'start' }}
            >
              <div style={{ display: 'inline-flex' }}>
                <a
                  href={DataPost}
                  style={AcceptBtn}
                  onClick={this.closeModal.bind(this, 'modal1')}
                >
                  I Accept
                </a>

                <p
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    color: '#345fa8',
                  }}
                >
                  -
                </p>

                <a
                  href="#"
                  onClick={this.closeModal.bind(this, 'modal1')}
                  style={AcceptBtn}
                >
                  I Refuse
                </a>
              </div>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        LegalEn: contentfulLegal(node_locale: { eq: "en-CA" }) {
          id
          title
          node_locale
          body {
            childContentfulRichText {
              html
            }
          }
        }
        DataEN: contentfulNational {
          id
          hpiData {
            id
            file {
              url
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <PopupDataHpi popupLegal={data} />
      </div>
    )}
  />
)
