import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import * as PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import Layout from '../components/Layouts/layout'
import Chart from '../components/Chart/national.charts_en'
import Sidebar from '../components/Side-bar/side-bar'
import Footersub from '../components/Footer/footer-sub'
import footerStyle from '../components/Footer/footer.module.css'
import ChartA from '../components/Chart/Natl_chartA'
import ChartB from '../components/Chart/Natl_chartB'
import PopupData from '../components/PopupData/popupdata'
import PopupDataHpi from '../components/PopupData/popupdata_hpi'
import StatsVideo from '../components/Stats video/stats-video'
import NewNew from '../components/Chart/nrs.charts.en'
import '../components/Content/content.css'
const propTypes = {
  data: PropTypes.object.isRequired,
}

const style = {
  marginBottom: '20px',
}

const modalStyle = {
  padding: '10px',
}

class RootENIndex extends React.Component {
  getYear() {
    return new Date().getFullYear()
  }
  constructor(props) {
    super(props)

    this.state = {
      dd1: false,
      modal1: false,
      modal2: false,
      chartA: false,
      chartB: false,
    }
    this.dropdownToggle = this.dropdownToggle.bind(this)
  }

  dropdownToggle() {
    this.setState({
      dd1: !this.state.dd1,
    })
  }
  closeModal(tabId) {
    this.setState({
      [tabId]: false,
    })
  }
  showModal = () => {
    this.setState({
      chartA: !this.state.chartA,
    })
  }
  showModal2 = () => {
    this.setState({
      chartB: !this.state.chartB,
    })
  }
  topFunction() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handeleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handeleScroll)
  }
  handeleScroll() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('myBtn').style.display = 'block'
    } else {
      document.getElementById('myBtn').style.display = 'none'
    }
  }
  render() {
    const natl = get(this, 'props.data.ca.edges[0].node')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    console.log('nrs', natl.NRSCharts)

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet title={`${natl.title} | ${siteTitle}`}></Helmet>
        {/* <img src={require('../images/logo_crea_EN.svg')} /> */}

        <div className="content-wrapper">
          <div className="row split">
            <div className="col-md">
              <div className="entry-header">
                <h1>National Statistics</h1>
              </div>

              <div className="entry-content">
                <div>
                  <h2>{natl.headline}</h2>

                  {/*    <a href={natl.news_release_video} target="_blank">
                    <b>
                      Watch: <u>CREA housing report</u>
                    </b>
                  </a> */}
                  <div className="mainFloatContainer">
                    <div className="LeftFloat ">
                      <div style={{ marginBottom: '20px' }}>
                        <h3>
                          <b>Monthly Housing Market Report</b>
                        </h3>
                        <StatsVideo />
                      </div>
                      <div>
                        <NewNew style={style} />
                        {/*  <Chart style={style} /> */}
                      </div>
                      {/*       <PopupData
                        style={modalStyle}
                        title="Download data"
                        body={
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html:
                                natl.creaLegal.body.childContentfulRichText
                                  .html,
                            }}
                          />
                        }
                      /> */}
                      <div style={{ textAlign: 'center' }}>
                        {' '}
                        <PopupData />
                      </div>
                    </div>
                    <div className="RightFloat ">
                      {natl.contentB !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: natl.contentB.childContentfulRichText.html,
                          }}
                        />
                      )}
                      {natl.contentA !== null && (
                        <div
                          className="content entry-sub"
                          dangerouslySetInnerHTML={{
                            __html: natl.contentA.childContentfulRichText.html,
                          }}
                        />
                      )}
                      {natl.chartA !== null && (
                        <div className="chart_container">
                          <div className="imageBox">
                            <ChartA className="thumbnail" />
                          </div>
                          <p>Chart A</p>
                        </div>
                      )}

                      {natl.contentC !== null && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: natl.contentC.childContentfulRichText.html,
                          }}
                        />
                      )}
                    </div>

                    {natl.chartB !== null && (
                      <div className="chart_container">
                        <div className="imageBox">
                          <ChartB className="thumbnail" />
                        </div>

                        <p>Chart B</p>
                      </div>
                    )}
                    {natl.contentD !== null && (
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: natl.contentD.childContentfulRichText.html,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Button
              onClick={this.topFunction}
              id="myBtn"
              title="Go to top"
              className="scroll-btn"
            >
              <img
                src={require('../images/btn_backtop.png')}
                alt="Scroll to top"
                title="Scroll to top"
              />
            </Button>

            <div className="col-sm- side-content mobile-style">
              <div className="chart">
                <hr />
                <Sidebar pathname="en-CA" />
              </div>
            </div>
            <div className="sheet">
              {/* <Sheet /> */}
              <br />
              <p>
                {/*  <a
                  href="https://www.crea.ca/housing-market-stats/mls-home-price-index/hpi-tool/"
                  target="_blank"
                >
                  {' '}
                  <u>download</u>
                </a>{' '} */}
                The MLS® HPI data is now available to download in Excel (.xlsx)
                format.
              </p>
              <PopupDataHpi />

              <embed src={natl.hpi} width="960" height="970" />
            </div>
          </div>
        </div>

        <Footersub />
        {/* <!--  ==========================================================  -->
  <!--  BOARD AND CREA DECLARATION HERE ==========================  -->
  <!--  ==========================================================  --> */}
        <div className={footerStyle.boardCredit}>
          <p>
            The information contained in this report has been prepared by The
            Canadian Real Estate Association drawn from sources deemed to be
            reliable, but the accuracy and completeness of the information is
            not guaranteed. In providing this information, The Canadian Real
            Estate Association does not assume any responsibility or liability.
            Copyright © {this.getYear()} The Canadian Real Estate Association.
            All rights reserved. Reproduction in whole or in part is prohibited
            without written permission.
          </p>
        </div>
        {/* <!--  ==========================================================  -->
  <!-- END EDITING PAGE CONTENT HERE =============================  -->
  <!--  ==========================================================  --></div> */}
      </Layout>
    )
  }
}

RootENIndex.propTypes = propTypes

export default RootENIndex

export const pageQuery = graphql`
  query HomeENQuery {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
      }
    }

    ca: allContentfulNational(
      filter: { node_locale: { eq: "en-CA" } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          creaLegal {
            title
            body {
              childContentfulRichText {
                html
              }
            }
          }

          title
          id
          contentful_id
          node_locale
          headline

          slug
          hpi
          chartA {
            fixed(width: 1079, height: 740) {
              src
              width
              height
              srcSet
            }
          }
          chartA {
            fluid(maxWidth: 1079, maxHeight: 740) {
              aspectRatio
              srcSet
              src
            }
          }
          chartB {
            fixed(width: 1079, height: 740) {
              src
              width
              height
              srcSet
            }
          }
          chartB {
            fluid(maxWidth: 1079, maxHeight: 740) {
              aspectRatio
              srcSet
              src
            }
          }
          publishDate
          contentB {
            childContentfulRichText {
              html
            }
          }
          contentA {
            childContentfulRichText {
              html
            }
          }
          
          contentC {
            childContentfulRichText {
              html
            }
          }
          contentD {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
    natltitle: allContentfulStatsPages(filter: { title: { eq: "About" } }) {
      ...Sourcex
    }
    en: allContentfulStatsPages(filter: { node_locale: { eq: "en-CA" } }) {
      ...Sourcex
    }
  }

  fragment Sourcex on ContentfulStatsPagesConnection {
    edges {
      node {
        title
        slug
        contentA {
          childContentfulRichText {
            html
          }
        }
        contentful_id
        node_locale
      }
    }
  }
`
